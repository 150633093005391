<template lang="pug">
  input.app-input(
    v-bind="$props"
    :class="{ changed, invalid }"
    :disabled="disabled"
    @input="handleInput"
    @wheel="$event.target.blur()"
    @keypress="preventNumericInput"
  )
</template>

<script>
  // misc
  import { ZERO_KEY_CODE, NINE_KEY_CODE } from "@/config/constants"

  export default {
    props: {
      value: {
        type: [Number, String],
        default: ""
      },
      type: {
        type: String,
        default: "text",
        validator: value => ["text", "number", "password"].includes(value)
      },
      min: {
        type: Number,
        default: undefined
      },
      max: {
        type: Number,
        default: undefined
      },
      maxlength: {
        type: Number,
        default: undefined
      },
      step: {
        type: Number,
        default: undefined
      },
      disabled: {
        type: Boolean,
        default: false
      },
      invalid: {
        type: Boolean,
        default: false
      },
      changed: {
        type: Boolean,
        default: false
      }
    },

    methods: {
      handleInput(event) {
        const validValue = this.getValidValue(event)

        this.$emit("input", validValue)
      },

      getValidValue(event) {
        const { valid } = event.currentTarget.validity
        const { value } = event.currentTarget

        this.$emit("update:invalid", !valid)

        const validValue = valid ? this.typeCoercedValue(value) : this.value

        event.currentTarget.value = validValue
        return validValue
      },

      typeCoercedValue(value) {
        switch (this.type) {
          case "number":
            return Number(value)

          default:
            return String(value)
        }
      },

      preventNumericInput(event) {
        if (this.type === "number" && (event.keyCode < ZERO_KEY_CODE || event.keyCode > NINE_KEY_CODE)) {
          event.preventDefault()
        }
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/mixins/common.sass"

  // Disable arrows in number inputs
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button
    -webkit-appearance: none
    margin: 0

  // Firefox
  input[type=number]
    -moz-appearance: textfield

  .app-input
    border: 1px solid $default-purple-light-line
    border-radius: 4px
    color: $link-color
    height: 24px

    &:disabled
      opacity: 0.3

      &:focus
        background: $default-purple-light
        color: $default-purple

    &.changed
      color: $default-purple !important
      background: $default-purple-light

    &.invalid
      +default-invalid-input
</style>
